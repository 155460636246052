$(document).foundation();
/*$(document).ready(function(){$('#exampleModal1').foundation('open')});*/
$(document).ready(function($) {
	/*$("body").foundation("_disableScroll", scrollTop);*/
	$("#twentyonemodal").foundation("open");
});


/*$(document).ready(function($) {
  if($.cookie('showed_modal') != "true") {
    $("#exampleModal1").foundation("open");
    $.cookie('showed_modal', 'true', { expires: 7, path: '/'}); 
  }
});*/

if (module.hot) {
    module.hot.accept();
}